body {
  background-color:azure;
}

.alert {
  padding: 11px;
  margin-bottom: 0;
}

.logo-col img {
  width: 48px;
}

.logo-col {
  max-width: 62px
}

.form-group {
  margin-bottom: 0;
}